<template>
  <form class="form-info" :class="{skeleton: !state.loaded}" @submit.prevent="submit()">
    <div class="wrapper">
      <div class="head">
        <div class="title">양식 {{ modalParams.type === "add" ? "추가" : "수정" }}</div>
        <button class="remove btn" type="button" title="삭제" @click="remove(modalParams.todoSeq)" v-if="modalParams.type !== 'add'">
          <i class="fa fa-trash"></i>
        </button>
      </div>
      <div class="body">
        <div class="form-group">
          <label class="font-xs" :for="`${component.name}Subject`">
            <span class="color-purple">*</span>
            <span> 제목</span>
          </label>
          <input :id="`${component.name}Title`" v-model="state.form.title" type="text" class="form-control"
                 placeholder="ex) 리뷰 설문조사 2023년 하반기" autocomplete="off" maxlength="100"/>
        </div>
        <div class="form-group">
          <label class="font-xs" :for="`${component.name}Category`">
            <span class="color-purple">*</span>
            <span> 분류</span>
          </label>
          <select :id="`${component.name}Category`" class="form-control" v-model="state.form.categoryId">
            <option :value="null">분류를 선택해주세요.</option>
            <option :value="c.id" v-for="(c, idx) in state.categories" :key="idx">{{ c.title }}</option>
          </select>
        </div>
        <div class="form-group">
          <label class="font-xs" :for="`${component.name}SendDate`">
            <span> 마감일</span>
          </label>
          <Date componentNameSuffix="S" :id="`${component.name}ExpiryDate`" class="font-sm" placeholder="마감일" :value.sync="state.form.expiryDate" format="yyyy-MM-dd"/>
        </div>
        <div class="form-group">
          <label class="font-xs" :for="`${component.name}Desc`">설명</label>
          <textarea :id="`${component.name}Desc`" v-model="state.form.description" type="text" class="form-control" @keyup.ctrl.enter="submit()"
                    maxlength="500" placeholder="ex) 리뷰 설문조사는 금년도 12월 31일까지 진행합니다." autocomplete="off"></textarea>
        </div>
        <div class="form-group check mb-0">
          <label class="font-xs">
            <span>중복 신청 여부</span>
          </label>
          <label class="switch" :for="`${component.name}duplication`" @click="changeDuplication">
            <input type="checkbox" :checked="state.form.duplication">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>
    <div class="action">
      <button class="btn btn-purple btn-block" type="submit">저장하기</button>
    </div>
  </form>
</template>

<script>
import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import lib from "@/scripts/lib";
import http from "@/scripts/http";
import Date from "@/components/Date.vue";

function Component(initialize) {
  this.name = "modalFormInfo";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Date},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      if (modalParams.form) {
        state.form = lib.getRenewed(modalParams.form);
      }

      state.loaded = false;
      http.get("/api/admin/form/categories").then(({data}) => {
        state.loaded = true;
        state.categories = data.body.content;
      });

      store.commit("tuneModal", {component, size: "sm"});
    });

    const state = reactive({
      loaded: true,
      categories: [],
      form: {
        title: "",
        description: "",
        categoryId: null,
        step: {
          title: "기본 정보",
          ord: 1,
        },
        duplication: false,
        expiryDate: "",
      }
    });

    const modalParams = store.getters.modalParams(component);

    const submit = () => {
      if (!state.form.title?.trim()) {
        document.getElementById(`${component.name}Title`)?.focus();
        return store.commit("setSwingMessage", "제목을 입력해주세요.");
      }

      const onClose = () => {
        store.commit("setSwingMessage", "양식 정보를 저장하였습니다.");
        close();
      };

      if (modalParams.type === "add") {
        http.post("/api/admin/forms", state.form).then(onClose);
      } else {
        http.put(`/api/admin/forms/${state.form.id}`, state.form).then(onClose);
      }
    };

    const close = () => {
      store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          store.dispatch("callback", {modal});
        }
      });
    };

    const remove = () => {
      store.commit("confirm", {
        message: "삭제하시겠습니까?",
        allow() {
          http.delete(`/api/admin/forms/${modalParams.form.id}`).then(() => {
            store.commit("setSwingMessage", "양식을 삭제하였습니다.");
            close();
          });
        }
      });
    };

    const changeDuplication = () => {
      return state.form.duplication === false ? state.form.duplication = true : state.form.duplication = false;
    };

    onMounted(() => {
      modalParams.type === "add" && document.getElementById(`${component.name}Title`)?.focus();
    });

    return {component, state, modalParams, submit, close, remove, changeDuplication};
  }
});
</script>

<style lang="scss" scoped>
.form-info {
  > .wrapper {
    background: #fff;
    padding: $px25;
  }

  .head {
    position: relative;
    margin-bottom: $px20;

    .title {
      font-size: $px18;
    }

    .remove {
      position: absolute;
      top: 0;
      right: 0;
      padding: $px9 $px5;
      margin: $px-5;
    }
  }

  .body {
    .form-group {
      font-size: $px14;

      input, textarea, select {
        background-color: #fff;
        font-size: $px13;
        transition: none;
      }

      input, textarea {
        padding: $px15;
      }

      &.check {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: $formHeight;

        .switch {
          position: relative;
          display: inline-block;
          width: $px50;
          height: $px21;
          margin-bottom: 0;

          > input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .slider {
              background-color: $colorPurple;

              &::before {
                transform: translate($px29, -50%);
              }
            }

            &:focus + .slider {
              box-shadow: 0 0 $px1 #2196F3;
            }
          }

          > .slider {
            cursor: pointer;
            background-color: #ccc;
            display: inline-block;
            position: relative;
            width: 100%;
            height: 100%;
            transition: .4s;

            &.round {
              border-radius: $px50;

              &::before {
                border-radius: 50%;
              }
            }

            &::before {
              position: absolute;
              content: "";
              height: $px13;
              width: $px13;
              left: $px4;
              top: 50%;
              transform: translateY(-50%);
              background-color: #fff;
              transition: 0.18s;
            }
          }
        }
      }
    }
  }

  .action {
    .btn {
      padding: $px15;
      height: $px60;
      border-radius: 0;
      font-size: $px13;

      &.remove {
        background: $colorSecondary;
        border-color: $colorSecondary;
        color: #fff;

        &:hover {
          background: $colorSecondaryActive;
          border-color: $colorSecondaryActive;
        }
      }
    }
  }

  &.skeleton {
    .head .remove {
      display: none;
    }

    .body {
      input, textarea, select {
        @include skeleton;
      }
    }
  }
}
</style>